<template>
    <div class="column-chooser">

        <b-avatar  id="popover-agreement-description"
                rounded

                variant="light-secondary cursor-pointer"
        >

            <feather-icon

                    size="18"
                    icon="ColumnsIcon"
            />
        </b-avatar>
        <!--triggers="hover"-->
        <b-popover
                class="bg-info"
                target="popover-agreement-description"
                triggers="hover"

                placement="top"
                variant="secondary"
        >
            <template #title>
                <span>{{$t('label_select_columns')}}</span>
            </template>
            <div class="tooltip-content">

                <div @click="solveOrNeedShow(item)" :class="item.is_removed? 'badge-secondary' : 'badge-primary'" class="badge mr-50 mb-50 cursor-pointer" v-for="item in preparedColumns" :key="item.key">
                    {{$t(item.label)}}
                    <!--<feather-icon v-if="!item.is_removed" icon="TrashIcon" color="red"></feather-icon>-->
                </div>
            </div>
        </b-popover>
    </div>
</template>
<script>
    import {BPopover, BAvatar} from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import Vue from 'vue'
    export default {
        components: {
            vSelect, BPopover, BAvatar
        },
        props: ['columns', 'notTouch', 'preselected'],
        data() {
            return {
                selectedColumns:[],
                preparedColumns:[],
                resultSelectedColumns:[],
            }
        },
        model:{
            prop: 'resultSelectedColumns',
            event: 'updateColumns'
        },

        watch:{
            selectedColumns : function(newVal){
                let res = [];
                 for(let i =0; i< this.columns.length;i++) {
                     if(this.notTouch.includes(this.columns[i].key)){
                         res.push(this.columns[i]);
                     } else {
                         let item  = this.selectedColumns.findWhere('key', this.columns[i].key);
                         if(item){
                             res.push(item);
                         }
                     }
                 }
                this.resultSelectedColumns = res;
                 // console.log('this.resultSelectedColumns',this.resultSelectedColumns);
                this.$emit('updateColumns', this.resultSelectedColumns);
            },
            columns: function(newVal){
                if(newVal) {
                    this.init();
                }
            }
        },
        methods:{
            init(){
                let self = this;
                let allowed = this.columns.filter(function(item){return !self.notTouch.includes(item.key)});
                this.preparedColumns = JSON.parse(JSON.stringify(allowed));

                this.selectedColumns = this.preselected? this.preselected : allowed;

            },
            solveOrNeedShow(item){
                if(!item.is_removed){
                    if(this.selectedColumns.length > 1) {
                        Vue.set(item, 'is_removed', true);
                        this.selectedColumns = this.selectedColumns.filter((o)=>{return o.key != item.key});
                    }

                } else {
                    Vue.set(item, 'is_removed', false);
                    this.selectedColumns.push(item);
                }
            }
        },

        created(){
            this.init();
        }
    }
</script>